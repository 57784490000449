import * as React from "react";
import Box from "@mui/material/Box";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {useMediaQuery} from "@mui/material";
import theme from "../assets/theme";
import MobileNavbar from "./Navbar/MobileNavbar";

const drawerWidth = 270;

interface Props {
  window?: () => Window;
  children: React.ReactNode;
}

export default function BaseLayout({children, window}: Props): JSX.Element {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleDrawerClose = (): void => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = (): void => {
    setIsClosing(false);
  };

  const handleDrawerToggle = (): void => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{display: "flex"}}>
      {!isMobile && <Navbar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />}
      {!!isMobile && (
        <MobileNavbar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
      )}
      <Sidebar
        drawerWidth={drawerWidth}
        container={container}
        mobileOpen={mobileOpen}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        handleDrawerClose={handleDrawerClose}
      />
      <Box
        component="main"
        sx={{
          py: 3,
          px: 2,
          width: "100vw",
        }}>
        <Box sx={{minHeight: "55px"}}></Box>
        <Box sx={{minHeight: "85vh"}}>{children}</Box>
        <Footer />
      </Box>
    </Box>
  );
}
