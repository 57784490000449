import axios, {type AxiosRequestHeaders} from "axios";
import {errorHandler} from "../api/errorHandler";

const AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL_ROOT}/api/V2/`,
  withCredentials: true,
});

AxiosInstance.interceptors.request.use(
  function (config) {
    let csrftoken = document.cookie
      .split(";")
      .find(cookie => cookie.trim().startsWith("csrftoken="));
    if (csrftoken && csrftoken.length > 0) csrftoken = csrftoken.split("=")[1];

    if (csrftoken) {
      config.headers = {
        ...config.headers,
        "X-CSRFToken": csrftoken,
      } as unknown as AxiosRequestHeaders;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

AxiosInstance.interceptors.response.use(
  response => response,
  error => {
    throw errorHandler(error) as Error;
  },
);

export default AxiosInstance;
