/* eslint-disable react/no-multi-comp */
import {Box} from "@mui/system";
import {ReactNode, useState} from "react";
import {DayPicker, DayPickerProps} from "react-day-picker";
import MyDateLabel from "../MyDateLabel";
import "react-day-picker/style.css";
import "./index.css";
import {Typography} from "@mui/material";
import SuccessAbortButtons from "../SuccessAbortButtons";
import {
  getDropdownNavStyles,
  getDropdownNavInnerStyles,
  getDateRangeBoxStyles,
  getTypographyStyles,
  getMonthGridStyles,
  getButtonSx,
} from "./styles";
import {DateRange} from "./types";

interface Props {
  showApplyCancelButtons: boolean;
  mode: "range" | "single" | "multiple";
  onSuccess: ({
    selectedDateRange,
    selectedDate,
  }: {
    selectedDateRange: DateRange;
    selectedDate: string;
  }) => void;
  onAbort: () => void;
}

function MyCalendar({
  showApplyCancelButtons,
  mode,
  onSuccess,
  onAbort,
  ...props
}: Props & DayPickerProps) {
  const [tempSelectedDateRange, setTempSelectedDateRange] = useState<DateRange | undefined>();
  const [tempSelectedDate, setTempSelectedDate] = useState();

  const getDayPickerModeBasedProps = () => {
    if (showApplyCancelButtons) {
      if (mode === "range")
        return {
          selected: tempSelectedDateRange as any,
          onSelect: setTempSelectedDateRange as any,
        };
      if (mode === "single") return {selected: tempSelectedDate, onSelect: setTempSelectedDate};
    }
    return {};
  };

  return (
    <DayPicker
      mode={mode as any}
      hideNavigation
      captionLayout="dropdown"
      fixedWeeks
      {...getDayPickerModeBasedProps()}
      components={{
        DropdownNav({children}: {children?: unknown}) {
          const [MonthDropdown, YearDropdown] = children as ReactNode[];
          return (
            <Box sx={getDropdownNavStyles()}>
              <Box sx={getDropdownNavInnerStyles()}>
                {YearDropdown}
                <Box className="monthDropdown">{MonthDropdown}</Box>
              </Box>
              {mode === "range" && (
                <Box sx={getDateRangeBoxStyles()}>
                  <MyDateLabel placeholder="Start Date" miliseconds={tempSelectedDateRange?.from} />
                  <Typography sx={getTypographyStyles()}> to </Typography>
                  <MyDateLabel miliseconds={tempSelectedDateRange?.to} placeholder="End Date" />
                </Box>
              )}
            </Box>
          );
        },
        MonthGrid({children}: {children?: unknown}) {
          return <Box sx={getMonthGridStyles()}>{children as ReactNode}</Box>;
        },
      }}
      footer={
        showApplyCancelButtons ? (
          <SuccessAbortButtons
            onAbort={onAbort}
            onSuccess={() =>
              onSuccess({
                selectedDateRange: tempSelectedDateRange,
                selectedDate: tempSelectedDate ?? "",
              })
            }
            abortButtonName="Cancel"
            successButtonName="Apply"
            buttonSx={getButtonSx()}
          />
        ) : (
          <></>
        )
      }
      {...props}
    />
  );
}

export default MyCalendar;
