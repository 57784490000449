import {createSlice} from "@reduxjs/toolkit";
import type {Action, Auth, ReduxState} from "../interface";
import {AuthUser} from "../../api/auth/auth.types";

const initialState: Auth = {
  isLoggedIn: false,
  user: undefined,
};

//Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state: Auth, action: Action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logoutSuccess(state: Auth) {
      state.isLoggedIn = false;
      state.user = undefined;
    },
    authenticateSuccess: (state: Auth, action: Action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
  },
});

export default authSlice.reducer;

//Actions
export const {loginSuccess, logoutSuccess, authenticateSuccess} = authSlice.actions;

//Selectors
export const selectIsLoggedIn = (state: ReduxState): boolean => state.auth.isLoggedIn;
export const selectUser = (state: ReduxState): AuthUser | undefined => state.auth.user;
