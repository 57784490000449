import {Navigate} from "react-router-dom";
import type {RouteObject} from "react-router-dom";
import {lazy} from "react";

const Login = lazy(() => import("./../pages/auth/Login"));
const SignUpForm = lazy(() => import("../pages/auth/SignUpForm"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));

export const authRoutes: RouteObject[] = [
  {path: "login", element: <Login />},
  {path: "sign-up", element: <SignUpForm />},
  {path: "reset-password/:id/:token", element: <ResetPassword />},
  {path: "*", element: <Navigate to={"/login"} />},
];
