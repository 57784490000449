import {AppBar, styled} from "@mui/material";

export const WhiteAppBar = styled(AppBar)(({theme}) => ({
  backgroundColor: theme.colors.tertiary.main,
  color: theme.colors.black.main,
  boxShadow: "none",
  backdropFilter: `blur(6px)`,
  WebkitBackdropFilter: `blur(6px)`,
  transition: "height 0.3s",
  height: 80,
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    height: 80, // Initial height for larger screens
  },
  "&.short": {
    height: 56, // Height when scrolled
    [theme.breakpoints.up("sm")]: {
      height: 64, // Height when scrolled for larger screens
    },
  },
}));
export default WhiteAppBar;
