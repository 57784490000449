import {CSSProperties} from "react";
import theme from "../../../../assets/theme";
import colors from "../../../../assets/theme/base/colors";
import blur from "../../../../assets/theme/base/blur";

export const DropdownIconStyles = () => {
  return {height: "20px", width: "20px", marginRight: 0};
};

export const PageSizeSelectSx = () => {
  return {
    "& .MuiSelect-outlined": {
      p: "0px",
      fontSize: "14px",
      fontWeight: "600",
    },
  };
};

export const PageSizeTypography = () => {
  return {
    fontSize: "14px",
    mr: 0.5,
    whiteSpace: "nowrap",
    color: colors.text.tertiary + blur["80%"],
  };
};

export const PageSizeSelectContainer = () => {
  return {display: "flex", mr: theme.breakpoints.down("lg") ? 0 : 3, alignItems: "center"};
};

export const CountValue = () => {
  return {fontSize: "14px", mr: 1, color: colors.text.primary};
};

export const CountTypography = () => {
  return {fontSize: "14px", mr: 1, color: colors.text.tertiary + blur["80%"]};
};

export const CountAndPageSizeContainer = (
  styles: CSSProperties | undefined,
  display: "flex" | "none",
) => {
  return {
    display: "flex",
    [theme.breakpoints.down("xl")]: {
      display: display,
    },
    ...styles,
  };
};

export const CountAndPageSizeContainerProp = () => {
  return {
    display: "none",
    justifyContent: "space-between",
    flex: 1,
    borderTop: `1px solid ${theme.colors.grey[300]}`,
    padding: "10px 0px",
    margin: "10px 0px",
  };
};

export const CountContainer = () => {
  return {
    display: "flex",
    mr: theme.breakpoints.down("lg") ? 1 : 3,
    alignItems: "center",
  };
};

export const SearchBarStylesProp = (showSearchBar: boolean) => {
  return {
    display: showSearchBar ? "flex" : "none",
    mt: 1,
    width: "100% !important",
    [theme.breakpoints.up("md")]: {display: "none"},
  };
};

export const getHeaderContainerStyles = () => ({
  display: "flex",
  justifyContent: "space-between",
  mb: "10px",
});

export const getTypographyStyles = () => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  color: colors.text.primary,
  fontWeight: 600,
});

export const getCountAndPageSizeStyles = () => ({
  marginRight: "32px",
});

export const getIconStyles = () => ({
  color: colors.text.tertiary,
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
});

export const getSearchIconContainerStyles = (showSearchBar: boolean) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: showSearchBar ? "none" : "flex",
    ml: "5px",
    mr: 0,
  },
});

export const getFilterIconContainerStyles = (isFiltered: boolean) => ({
  p: "5px",
  mx: 2,
  backgroundColor: isFiltered ? theme.colors.tertiary.main : "transparent",
});

export const getPeriodSelectStyles = () => ({
  fontSize: "14px",
  bgcolor: theme.colors.tertiary.main,
  color: colors.grey["600"],
  fontWeight: "550",
  width: "122px",
  p: "8px 6px",
  [theme.breakpoints.down("sm")]: {
    width: "90px",
    fontSize: "12px",
  },
  "& .MuiSelect-select": {
    p: 0,
    pr: "8px !important",
    pl: 1,
  },
});
