import type {CSSProperties} from "@mui/material/styles/createMixins";
import type {MyThemeOptions} from "../../assets/theme/interface";

export function navIcon(theme: MyThemeOptions): CSSProperties {
  const {colors} = theme;

  return {
    color: colors.text.secondary,
    fontSize: "35px",
    cursor: "pointer",
  };
}

export function navIconContainer(): CSSProperties {
  return {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
  };
}
