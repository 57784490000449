import {
  ACT_400_EMAIL_ALREADY_REGISTERED,
  ACT_400_INVALID_REQUEST_BODY,
  ACT_400_MFA_INVALID_OTP,
  ACT_400_PASSWD_INVALID_LINK,
  ACT_400_SERIALIZER_INPUT_INVALID,
  ACT_401_INACTIVE_ACCOUNT,
  ACT_401_INVALID_LOGIN_CREDENTIALS,
  ACT_403_INVALID_ACCOUNT_CONFIGURATION,
  ACT_403_OTP_NOT_FOUND,
  ACT_404_USER_WITH_EMAIL_NOT_FOUND,
  ACT_409_USER_ALREADY_EXISTS,
  ACT_422_EMAIL_NOT_VERIFIED,
  ACT_500_API_RESPONSE_SERIALIZER_ERROR,
  ACT_500_INTERNAL_SERVER_ERROR,
  ERR_500_INTERNAL_SERVER_ERROR,
  ERR_503_SERVER_TIMEOUT,
  MDLW_403_CSRF_INVALID_TOKEN_FORMAT,
  MDLW_403_CSRF_REJECT_REQUEST,
  MDLW_403_INVALID_ACCOUNT_CONFIGURATION,
} from "./ApiErrorCodes";
import {
  EMAIL_ALREADY_REGISTERED,
  EMAIL_NOT_REGISTERED,
  EMAIL_NOT_VERIFIED,
  INACTIVE_ACCOUNT,
  INVALID_CREDENTIALS,
  INVALID_OTP,
  INVALID_PASSWORD_LINK,
  SOMETHING_WENT_WRONG,
} from "./ErrorMessages";

export const ACT_ERROR: {[key: string]: string} = {
  [ACT_401_INACTIVE_ACCOUNT]: INACTIVE_ACCOUNT,
  [ACT_400_EMAIL_ALREADY_REGISTERED]: EMAIL_ALREADY_REGISTERED,
  [ACT_409_USER_ALREADY_EXISTS]: EMAIL_ALREADY_REGISTERED,
  [ACT_403_OTP_NOT_FOUND]: INVALID_OTP,
  [ACT_400_MFA_INVALID_OTP]: INVALID_OTP,
  [ACT_404_USER_WITH_EMAIL_NOT_FOUND]: EMAIL_NOT_REGISTERED,
  [ERR_500_INTERNAL_SERVER_ERROR]: SOMETHING_WENT_WRONG,
  [ACT_400_INVALID_REQUEST_BODY]: SOMETHING_WENT_WRONG,
  [MDLW_403_CSRF_REJECT_REQUEST]: SOMETHING_WENT_WRONG,
  [MDLW_403_CSRF_INVALID_TOKEN_FORMAT]: SOMETHING_WENT_WRONG,
  [ACT_403_INVALID_ACCOUNT_CONFIGURATION]: SOMETHING_WENT_WRONG,
  [ACT_400_PASSWD_INVALID_LINK]: INVALID_PASSWORD_LINK,
  [ERR_503_SERVER_TIMEOUT]: SOMETHING_WENT_WRONG,
  [ACT_401_INVALID_LOGIN_CREDENTIALS]: INVALID_CREDENTIALS,
  [MDLW_403_INVALID_ACCOUNT_CONFIGURATION]: SOMETHING_WENT_WRONG,
  [ACT_422_EMAIL_NOT_VERIFIED]: EMAIL_NOT_VERIFIED,
  [ACT_500_API_RESPONSE_SERIALIZER_ERROR]: SOMETHING_WENT_WRONG,
  [ACT_500_INTERNAL_SERVER_ERROR]: SOMETHING_WENT_WRONG,
  [ACT_400_SERIALIZER_INPUT_INVALID]: SOMETHING_WENT_WRONG,
};
