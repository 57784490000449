import theme from "../../../assets/theme";

export const getModalContainerStyles = (): any => ({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1200,
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#00000099",
});

export const getModalContentStyles = (): any => ({
  bgcolor: "white",
  boxShadow: "1px 1px 20px 1px grey",
  borderRadius: theme.borders.borderRadius.lg,
  p: "20px",
});
