import {Box, IconButton, Toolbar} from "@mui/material";
import React, {MouseEventHandler, useState} from "react";
import brand from "./../../assets/images/logos/logo.png";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SearchIcon from "@mui/icons-material/Search";
import {navIcon, navIconContainer} from "./styles";
import WhiteAppBar from "./WhiteAppBar";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";

interface Props {
  drawerWidth: number;
  handleDrawerToggle: MouseEventHandler<HTMLButtonElement>;
}

const MobileNavbar = ({drawerWidth, handleDrawerToggle}: Props) => {
  const [short, setShort] = useState(false);

  const handleScroll = (): void => {
    setShort(window.scrollY > 50);
  };

  window.onscroll = handleScroll;
  return (
    <Box>
      <WhiteAppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          ml: {sm: `${drawerWidth}px`},
        }}
        className={short ? "short" : ""}>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
          }}>
          <Box component="img" src={brand} sx={{width: "9rem"}} />
          <Box sx={navIconContainer()}>
            <IconButton>
              <SearchIcon sx={theme => navIcon(theme)} />
            </IconButton>
            <IconButton>
              <NotificationsNoneIcon sx={theme => navIcon(theme)} />
            </IconButton>
            <IconButton aria-label="open drawer" onClick={handleDrawerToggle}>
              <WidgetsRoundedIcon sx={theme => navIcon(theme)} />
            </IconButton>
          </Box>
        </Toolbar>
      </WhiteAppBar>
    </Box>
  );
};

export default MobileNavbar;
