export const createDynamicUrl = (endpoint: string, params?: Record<string, string | string[]>) => {
  if (!params) return endpoint;

  let url = endpoint;

  for (const [key, value] of Object.entries(params)) {
    if (url.includes(`:${key}`)) {
      url = url.replace(`:${key}`, encodeURIComponent(Array.isArray(value) ? value[0] : value));
      delete params[key];
    }
  }

  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach(val => queryParams.append(key, val));
    } else {
      queryParams.append(key, value);
    }
  }

  return queryParams.toString() ? `${url}?${queryParams.toString()}` : url;
};
