import {toast} from "react-toastify";
import type {Id, ToastOptions} from "react-toastify";

export const errorToast = (message: string, options?: ToastOptions): Id => {
  return toast.error(message, {
    ...options,
  });
};

export const successToast = (message: string, options?: ToastOptions): Id => {
  return toast.success(message, {
    ...options,
  });
};
