export const maskWord = function (str: string) {
  if (str.length < 2) return "";
  return "*".repeat(str.length - 2) + str.slice(-2);
};

export const maskEmail = function (email: string) {
  const arr = email.split("@");
  return maskWord(arr[0]) + "@" + arr[1];
};

export const truncateSentence = function (str: string, n: number) {
  return str.length > n ? str.slice(0, n) + "..." : str;
};
