import React from "react";
import {Box, Typography, Modal} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {centeredBoxStyles, imageStyles} from "./styles";
import recordEndLoader from "../../../assets/images/recording/recordEndLoader.gif";

interface Props {
  open: boolean;
}

const SoapNotesInProgressModal = ({open}: Props) => {
  const theme = useTheme();
  return (
    <Modal open={open} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box style={centeredBoxStyles()}>
        <Box component="img" src={recordEndLoader} alt="Loading" sx={imageStyles} />
        <Typography id="modal-title" variant="h4" sx={{fontWeight: 600}}>
          Hurray!
        </Typography>
        <Typography
          id="modal-description"
          sx={{mt: 2, textAlign: "center", color: theme.colors.text.secondary}}>
          <b>Generating SOAP Notes...</b>Please wait for <b>30-40 secs</b> to generate your SOAP
          Notes
        </Typography>
      </Box>
    </Modal>
  );
};

export default SoapNotesInProgressModal;
