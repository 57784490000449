import rgba from "./rgba";
import pxToRem from "./pxToRem";

// eslint-disable-next-line default-param-last
function boxShadow(
  color: string,
  opacity: number,
  offset: [number, number] = [0, 0],
  radius: [number, number] = [0, 0],
  inset: string = "",
): string {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
    color,
    opacity,
  )}`;
}

export default boxShadow;
