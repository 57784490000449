import {ERR_500_INTERNAL_SERVER_ERROR, ERR_503_SERVER_TIMEOUT} from "../constants/ApiErrorCodes";

export interface Error {
  config?: any;
  request?: any;
  response?: any;
}

export const errorHandler = (error: Error): any => {
  const {request, response} = error;
  if (response) {
    let {code} = response.data;
    const {detail, errors} = response.data;
    if (!code) {
      code = ERR_500_INTERNAL_SERVER_ERROR;
    }

    return {
      code,
      detail,
      errors,
      status: response.status,
      method: error.config.method,
      url: error.config?.url,
    };
  } else if (request) {
    //request sent but no response received
    return {
      request,
      code: ERR_503_SERVER_TIMEOUT,
      url: error.config.url,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      code: ERR_500_INTERNAL_SERVER_ERROR,
      errors: error,
      url: error.config?.url,
    };
  }
};
