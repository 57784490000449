import React, {ReactElement} from "react";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import {selectIsLoggedIn} from "../redux/slice/authSlice";
import {useAuthenticateUserQueryResult} from "../hooks/auth/query";
import PageLoader from "../components/common/PageLoader";

interface Props {
  children: ReactElement;
  protectionNeeded: boolean;
}

const RouteProtection = ({children, protectionNeeded}: Props): JSX.Element => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const {isLoading, isSuccess} = useAuthenticateUserQueryResult({
    enabled: !isLoggedIn,
    retry: 0,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  if (isLoading) return <PageLoader />;
  return (isSuccess || isLoggedIn) && protectionNeeded ? (
    children
  ) : !isSuccess && protectionNeeded ? (
    <Navigate to="/login" />
  ) : !protectionNeeded && isSuccess ? (
    <Navigate to="/home" />
  ) : (
    children
  );
};

export default RouteProtection;
