import {CSSProperties} from "@mui/material/styles/createMixins";
import borders from "../../../assets/theme/base/borders";

export const getTypographyStyles = (
  color: string,
  fontWeight: number = 400,
  fontSize: string = "14px",
) => ({
  color,
  fontWeight,
  fontSize,
});

export const getTrCellStyles = () => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
});

export const getTypeOfVisitStyles = (backgroundColor: string) => ({
  background: backgroundColor,
  fontSize: "14px",
  padding: "5px",
  borderRadius: borders.borderRadius.md,
  fontWeight: 500,
  pl: 1.5,
});

export function recordBadgeStyles(): CSSProperties {
  return {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "0.5rem",
    padding: "0.4rem",
  };
}

export function statusTextStyles(): CSSProperties {
  return {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
  };
}
