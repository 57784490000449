/* eslint-disable no-unused-vars */
import type {Action} from "@reduxjs/toolkit";
import {errorToast} from "../../components/common/Toasts";

interface Payload {
  method: string;
}

interface IAction extends Action {
  payload: Payload;
}

export const errorHandlerMiddleware =
  () => (next: (action: Action) => Action) => (action: IAction) => {
    if (action?.type?.includes("rejected")) {
      if (action?.payload?.method !== "get") {
        errorToast("Error");
      }
    }

    return next(action);
  };
