import type {UseMutationResult} from "@tanstack/react-query";
import {useMutation} from "@tanstack/react-query";
import type {
  RecordIdPayload,
  AudioUploadSignedUrlResponse,
  CreateRecordResponse,
  CreateRecordPayload,
} from "../../api/record/record.types";
import {createAudioUploadSignedUrl, completeRecord, createRecord} from "../../api/record/mutation";

import {useDispatch} from "react-redux";
import {setAppointmentId} from "../../redux/slice/recordSlice";

export const useCreateRecord = (): UseMutationResult<
  CreateRecordResponse,
  Error,
  CreateRecordPayload
> => {
  const dispatch = useDispatch();

  return useMutation<CreateRecordResponse, Error, CreateRecordPayload>({
    mutationFn: createRecord,
    onSuccess: data => {
      const appointmentId = data?.appointment_id;
      if (appointmentId) {
        dispatch(setAppointmentId({appointmentId}));
      }
    },
  });
};

export const useCreateAudioUploadSignedUrl = (): UseMutationResult<
  AudioUploadSignedUrlResponse,
  Error,
  RecordIdPayload
> => {
  return useMutation<AudioUploadSignedUrlResponse, Error, RecordIdPayload>({
    mutationFn: createAudioUploadSignedUrl,
  });
};

export const useCompleteRecord = (): UseMutationResult<
  CreateRecordResponse,
  Error,
  RecordIdPayload
> => {
  return useMutation<CreateRecordResponse, Error, RecordIdPayload>({
    mutationFn: completeRecord,
  });
};
