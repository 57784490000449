export type GetSoapNoteResponse = {
  soap_note_data: any;
};

export type SoapNoteGenerationStatusResponse = {
  soap_note_generation_status: string;
  updated_at: string;
};

export enum SOAP_NOTE_GENERATION_STATUS {
  INPROGRESS = "in_progress",
  PENDING = "pending",
  FAILED = "failed",
  COMPLETED = "completed",
}
