import {Box, Typography} from "@mui/material";
import React from "react";
import MyButton from "../../components/common/MyButton";
import {useNavigate} from "react-router-dom";
import Internal404Error from "./../../assets/images/error/Internal404Error.svg";

const Internal404ErrorPage = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "primary",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}>
      <Box component="img" sx={{height: "434px", width: "434px"}} src={Internal404Error} />
      <Typography variant="h5" fontWeight={"500"} sx={{mt: 4}}>
        Aww, something’s wrong :(
      </Typography>
      <Typography sx={{maxWidth: "400px", textAlign: "center", mt: 2}}>
        Looks like you’ve wandered off. Our team is working to get you back on track. Don’t panic.
      </Typography>
      <MyButton
        text="Back to home"
        style={{marginTop: 3, textAlign: "center"}}
        onClick={() => navigate(-1)}
      />
    </Box>
  );
};

export default Internal404ErrorPage;
