import {QueryClient} from "@tanstack/react-query";
import {ACT_ERROR} from "../constants/ApiErrorMessages";
import {errorToast} from "../components/common/Toasts";
import {Error} from "../shared/interfaces";
import {
  FORGOT_PASSWORD_API_URL,
  LOGIN_API_URL,
  SEND_EMAIL_OTP_API_URL,
  VERIFY_EMAIL_OTP_API_URL,
} from "../constants/ApiUrls";

const excludedEndpointsForErrorMessage = [
  LOGIN_API_URL,
  FORGOT_PASSWORD_API_URL,
  SEND_EMAIL_OTP_API_URL,
  VERIFY_EMAIL_OTP_API_URL,
];

export const onError = (error: unknown) => {
  if (typeof error === "object" && error !== null) {
    const customError = error as Error;
    if (!excludedEndpointsForErrorMessage.includes(customError.url)) {
      errorToast(ACT_ERROR[customError.code]);
    }
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError,
    },
  },
});
