import {
  extractDateFromTimestamp,
  getMonthStartAndEnd,
  getWeekStartAndEnd,
} from "../../../utils/DateUtils";
import {SelectedDateType} from "../MyCalendar/types";
import {SelectedPeriod} from "./types";

export function getStartDateBasedOnPeriod(
  selectedPeriod: SelectedPeriod,
  selectedDateRange: SelectedDateType,
  showCalender: boolean,
) {
  const currentTimestamp = new Date().toISOString();
  const formattedCurrentDate = extractDateFromTimestamp(currentTimestamp, "yyyy/mm/dd", "-");
  let dateStart = formattedCurrentDate;
  let dateEnd = formattedCurrentDate;
  let dates = {firstDate: "", lastDate: ""};

  switch (selectedPeriod) {
    case SelectedPeriod.WEEK:
      dates = getWeekStartAndEnd(currentTimestamp);
      dateStart = dates.firstDate;
      dateEnd = dates.lastDate;
      break;
    case SelectedPeriod.MONTH:
      dates = getMonthStartAndEnd(currentTimestamp);
      dateStart = dates.firstDate;
      dateEnd = dates.lastDate;
      break;
    case SelectedPeriod.CUSTOM:
      if (!showCalender && !selectedDateRange?.modified) {
        return {
          dateStart: formattedCurrentDate,
          dateEnd: formattedCurrentDate,
          period: SelectedPeriod.TODAY,
        };
      }
      if (selectedDateRange?.range?.from && selectedDateRange.range.to) {
        dateStart = extractDateFromTimestamp(
          new Date(selectedDateRange.range.from).toISOString(),
          "yyyy/mm/dd",
          "-",
        );
        dateEnd = extractDateFromTimestamp(
          new Date(selectedDateRange.range.to).toISOString(),
          "yyyy/mm/dd",
          "-",
        );
      }
      break;
  }
  return {dateStart, dateEnd, period: selectedPeriod};
}
