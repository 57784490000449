import {SxProps, Theme} from "@mui/material";
import theme from "../../../../assets/theme";

export const SearchBarStyles = (showSearchBar: boolean): SxProps<Theme> => {
  return {
    "& hover": {
      border: "1px solid red",
    },
    "& .MuiInputBase-input": {
      [theme.breakpoints.down("lg")]: {
        width: showSearchBar ? "100%" : "12vw !important",
      },
    },
  };
};
