import {SxProps, Theme} from "@mui/system";
import colors from "../../../assets/theme/base/colors";

export const getAbortButtonStyles = (): SxProps<Theme> | undefined => ({
  color: colors.primary.focus + "!important",
  padding: "10px 40px !important",
  "&:hover": {border: `1px solid ${colors.primary.focus}`},
  fontSize: "14px !important",
  fontWeight: "550 !important",
});

export const getSuccessButtonStyles = (): SxProps<Theme> | undefined => ({
  backgroundColor: colors.primary.focus + "!important",
  color: colors.white.main + "!important",
  padding: "10px 40px !important",
  fontSize: "14px !important",
  fontWeight: "550 !important",
});
