import {Box, Drawer} from "@mui/material";
import React from "react";
import DrawerBox from "./components/DrawerBox";
import theme from "../../assets/theme";
import {useGetAppointmentListQuery} from "../../hooks/appointment/query";
import {getCurrentDate} from "../../utils/DateUtils";

interface Props {
  drawerWidth: number;
  container: (() => HTMLElement) | undefined;
  mobileOpen: boolean;
  handleDrawerTransitionEnd: React.TransitionEventHandler<HTMLDivElement> | undefined;
  handleDrawerClose: () => void;
}

const Sidebar = ({
  drawerWidth,
  container,
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
}: Props): JSX.Element => {
  const {data: {results} = {results: []}} = useGetAppointmentListQuery({
    appointment_date_start: getCurrentDate(),
    appointment_date_end: getCurrentDate(),
    limit: 30,
    offset: 0,
    order_by_desc: true,
  });

  return (
    <Box>
      <Box
        component="nav"
        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: {xs: "block", sm: "none"},
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "300px",
              backgroundColor: theme.colors.secondary.main,
            },
          }}>
          <DrawerBox appointments={results} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {xs: "none", sm: "block"},
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: theme.colors.secondary.main,
              borderRight: "0px",
            },
          }}
          open>
          <DrawerBox appointments={results} />
        </Drawer>
      </Box>
    </Box>
  );
};

export default Sidebar;
