import colors from "./colors";

import boxShadow from "../functions/boxShadow";
import type {BoxShadows} from "../interface";

const {black, white, info, tabs, dark, boxShadowColors} = colors;

const boxShadows: BoxShadows = {
  xs: boxShadow(black.main, 0.15, [0, 2], [9, -5]),
  sm: boxShadow(black.main, 0.12, [0, 5], [10, 0]),
  md: `${boxShadow(black.light, 0.12, [0, 4], [6, -1])}, ${boxShadow(
    black.light,
    0.07,
    [0, 2],
    [4, -1],
  )}`,
  lg: `${boxShadow(black.light, 0.15, [0, 8], [26, -4])}, ${boxShadow(
    black.light,
    0.06,
    [0, 8],
    [9, -5],
  )}`,
  xl: boxShadow(black.light, 0.25, [0, 23], [45, -11]),
  xxl: boxShadow(black.main, 0.05, [0, 20], [27, 0]),
  inset: boxShadow(black.main, 0.075, [0, 1], [2, 0], "inset"),
  navbarBoxShadow: `${boxShadow(white.main, 0.9, [0, 0], [1, 1], "inset")}, ${boxShadow(
    black.main,
    0.05,
    [0, 20],
    [27, 0],
  )}`,
  cardBoxShadow: `${boxShadow("#67748e", 0.075, [0, 0], [16, 0])}`,
  dropDownBoxShadow: `${boxShadow("#E2E2E2", 0.075, [0, 3], [6, 0])}`,
  buttonBoxShadow: {
    main: `${boxShadow(dark.main, 0.1, [0, 4], [6, 0])}, ${boxShadow(
      black.main,
      0.08,
      [0, 1],
      [3, 0],
    )}`,
    stateOf: `${boxShadow(dark.main, 0.1, [0, 7], [14, 0])}, ${boxShadow(
      black.main,
      0.08,
      [0, 3],
      [6, 0],
    )}`,
    stateOfNotHover: boxShadow(info.main, 0.5, [0, 0], [0, 3.2]),
  },
  inputBoxShadow: `${boxShadow(info.main, 0, [0, 3], [9, 0])}, ${boxShadow(
    info.main,
    0.1,
    [3, 4],
    [8, 0],
  )}`,
  sliderBoxShadow: {
    thumb: boxShadow(black.main, 0.2, [0, 1], [13, 0]),
  },
  tabsBoxShadow: {
    indicator: boxShadow(tabs.indicator.boxShadow, 1, [0, 1], [5, 1]),
  },
  accordianShadowOnHover: `${boxShadow(boxShadowColors.accordian.primary, 1, [0, 4], [8, 0])}`,
};

export default boxShadows;
