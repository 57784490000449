import type {CSSProperties} from "@mui/material/styles/createMixins";
import hexToRgb from "../../assets/theme/functions/hexToRgb";
import type {MyThemeOptions} from "../../assets/theme/interface";

export function listItem(theme: MyThemeOptions, active: boolean): CSSProperties {
  const {colors, borders} = theme;

  return {
    borderRadius: `${borders.borderRadius.md} 0px 0px ${borders.borderRadius.md}`,
    width: "95%",
    padding: "0px",
    marginTop: "2px",
    marginBottom: "2px",
    justifyContent: "flex-end",
    "&:hover": {
      backgroundColor: `rgba(${hexToRgb(colors.grey["200"])},0.8)`,
    },
    "&:active": {
      backgroundColor: theme.colors.white.main,
    },
    ...(active && {
      color: colors.primary.main,
      fontWeight: "fontWeightSemiBold",
      bgcolor: theme.colors.white.main,
      "&:hover": {
        bgcolor: `rgba(${hexToRgb(colors.primary.main)},
        0.25)`,
      },
    }),
  };
}

export function listItemButton(theme: MyThemeOptions, active: boolean): CSSProperties {
  const {borders} = theme;
  return {
    padding: "5px",
    borderRadius: `${borders.borderRadius.md} 0px 0px ${borders.borderRadius.md}`,
    px: "10px",
    "&:hover": {backgroundColor: "transparent"},
    "&:clicked": {borderRadius: "10px"},
    ...(active && {
      borderRight: `5px solid ${theme.colors.primary.main}`,
    }),
  };
}

export function listItemText(theme: MyThemeOptions, active: boolean): CSSProperties {
  const {colors} = theme;

  return {
    padding: "0px",
    color: colors.grey[600],
    ...(active && {
      color: colors.primary.main,
      "& .css-10hburv-MuiTypography-root": {
        fontWeight: 550,
      },
    }),
    "& .css-10hburv-MuiTypography-root": {
      fontWeight: 420,
    },
  };
}

export function listItemIcon(theme: MyThemeOptions, active: boolean): CSSProperties {
  const {colors} = theme;

  return {
    color: colors.grey[600],
    minWidth: "35px",
    mx: 1,
    ...(active && {
      color: colors.primary.main,
    }),
  };
}
