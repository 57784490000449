import {createSlice} from "@reduxjs/toolkit";
import {CurrentRecordingState, ReduxState} from "../interface";

const initialState: CurrentRecordingState = {
  isRecording: false,
  recordId: null,
  appointmentId: null,
  showRecordWarningModal: false,
};

const recordSlice = createSlice({
  name: "currentRecording",
  initialState,
  reducers: {
    startRecording(state) {
      state.isRecording = true;
    },
    stopRecording(state) {
      state.isRecording = false;
    },
    setAppointmentId(state, action) {
      state.appointmentId = action.payload.appointmentId;
    },
    setRecordId(state, action) {
      state.recordId = action.payload.recordId;
    },
    showRecordWarningModal(state) {
      state.showRecordWarningModal = true;
    },
    hideRecordWarningModal(state) {
      state.showRecordWarningModal = false;
    },
  },
});

export const selectIsRecording = (state: ReduxState): boolean => state.currentRecording.isRecording;
export const selectShowRecordWarningModal = (state: ReduxState): boolean =>
  state.currentRecording.showRecordWarningModal;
export const selectCurrentAppointmentId = (state: ReduxState): number | null =>
  state.currentRecording.appointmentId;

export const {
  startRecording,
  stopRecording,
  setAppointmentId,
  setRecordId,
  showRecordWarningModal,
  hideRecordWarningModal,
} = recordSlice.actions;
export default recordSlice.reducer;
