import {Box, BoxProps, SvgIconTypeMap} from "@mui/material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import colors from "../../../assets/theme/base/colors";

interface Props extends React.ComponentProps<OverridableComponent<SvgIconTypeMap<object, "svg">>> {
  Icon: () => JSX.Element | any;
  containerProps?: BoxProps;
}

const MyIcon = ({Icon, containerProps, ...props}: Props) => {
  return (
    <Box
      {...containerProps}
      sx={{
        p: "5px",
        borderRadius: "50px",
        border: `1px solid ${colors.light.secondary}`,
        filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
        mr: 1,
        display: "flex",
        cursor: "pointer",
        ...(containerProps?.sx || {}),
      }}>
      <Icon {...props} />
    </Box>
  );
};

export default MyIcon;
