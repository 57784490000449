import {Box, Typography, useMediaQuery} from "@mui/material";
import {useLocation} from "react-router-dom";
import MyListItem from "../../MyListItem";
import {SideNavTabs} from "../SideNavTabs";
import AppointmentList from "./AppointmentList";
import {Appointment} from "../../../api/appointment/appointment.interface";
import brand from "./../../../assets/images/logos/logo.png";
import theme from "../../../assets/theme";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/slice/authSlice";

interface Props {
  appointments: Appointment[];
}

export default function DrawerBox({appointments}: Props): JSX.Element {
  const location = useLocation();
  const {pathname} = location;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector(selectUser);

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      {!isMobile && (
        <Box
          component="img"
          src={brand}
          data-testid="webAppLogo"
          sx={{width: "12rem", margin: "auto", my: 3}}
        />
      )}
      {!!isMobile && <Box component="img" src={brand} sx={{width: "10rem", my: 3, ml: 3}} />}
      {!!isMobile && (
        <Box sx={{display: "flex", flexDirection: "row", ml: 2}}>
          <Box
            sx={{
              height: "70px",
              width: "70px",
              backgroundColor: "white",
              borderRadius: theme.borders.borderRadius.md,
              mb: 3,
            }}></Box>
          <Box sx={{ml: 2, mt: 1}}>
            <Typography
              variant="h5"
              fontWeight={"550"}>{`${user?.first_name} ${user?.last_name}`}</Typography>
            <Typography sx={{textTransform: "capitalize"}} variant="subtitle1">
              {user?.practitioner_role}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
        }}>
        {SideNavTabs.map(tab => {
          return <MyListItem key={tab.key} tab={tab} active={pathname === tab.path} />;
        })}
      </Box>
      <AppointmentList appointments={appointments} />
    </Box>
  );
}
