export type EmrSystem = {
  id: number;
  emr_name: string;
  emr_version: string;
};

export enum EMR_PUSH_STATUS {
  NONE = "none",
  COMPLETED = "completed",
  FAILED = "failed",
  PENDING = "pending",
  SKIPPED = "skipped",
}
