export const ACT_401_INACTIVE_ACCOUNT = "ACT_401_INACTIVE_ACCOUNT";
export const ACT_400_EMAIL_ALREADY_REGISTERED = "ACT_400_EMAIL_ALREADY_REGISTERED";
export const ACT_409_USER_ALREADY_EXISTS = "ACT_409_USER_ALREADY_EXISTS";
export const ACT_400_MFA_INVALID_OTP = "ACT_400_MFA_INVALID_OTP";
export const ACT_403_OTP_NOT_FOUND = "ACT_403_OTP_NOT_FOUND";
export const ERR_500_INTERNAL_SERVER_ERROR = "ERR_500_INTERNAL_SERVER_ERROR";
export const MDLW_403_CSRF_REJECT_REQUEST = "MDLW_403_CSRF_REJECT_REQUEST";
export const MDLW_403_CSRF_INVALID_TOKEN_FORMAT = "MDLW_403_CSRF_INVALID_TOKEN_FORMAT";
export const ACT_403_INVALID_ACCOUNT_CONFIGURATION = "ACT_403_INVALID_ACCOUNT_CONFIGURATION";
export const ACT_400_PASSWD_INVALID_LINK = "ACT_400_PASSWD_INVALID_LINK";
export const ERR_503_SERVER_TIMEOUT = "ERR_503_SERVER_TIMEOUT";
export const ACT_400_INVALID_REQUEST_BODY = "ACT_400_INVALID_REQUEST_BODY";
export const ACT_401_INVALID_LOGIN_CREDENTIALS = "ACT_401_INVALID_LOGIN_CREDENTIALS";
export const MDLW_403_INVALID_ACCOUNT_CONFIGURATION = "MDLW_403_INVALID_ACCOUNT_CONFIGURATION";
export const ACT_404_USER_WITH_EMAIL_NOT_FOUND = "ACT_404_USER_WITH_EMAIL_NOT_FOUND";
export const ACT_422_EMAIL_NOT_VERIFIED = "ACT_422_EMAIL_NOT_VERIFIED";
export const ACT_500_API_RESPONSE_SERIALIZER_ERROR = "ACT_500_API_RESPONSE_SERIALIZER_ERROR";
export const ACT_500_INTERNAL_SERVER_ERROR = "ACT_500_INTERNAL_SERVER_ERROR";
export const ACT_400_SERIALIZER_INPUT_INVALID = "ACT_400_SERIALIZER_INPUT_INVALID";
