import {Box, styled} from "@mui/material";
import theme from "../../../assets/theme";

const ScrollableBox = styled(Box)(() => ({
  overflow: "auto",
  "::-webkit-scrollbar": {
    width: "6px",
  },
  "::-webkit-scrollbar-track": {
    background: theme.colors.secondary.main,
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: theme.colors.background.scrollbar,
    borderRadius: "10px",
  },
}));

export default ScrollableBox;
