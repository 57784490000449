import axios from "axios";
import AxiosInstance from "../../axios/axiosInstance";
import {errorToast} from "../../components/common/Toasts";
import {
  COMPLETE_RECORD_API_URL,
  CREATE_RECORD_API_URL,
  CREATE_SIGNED_API_URL,
} from "../../constants/ApiUrls";
import {AUDIO_UPLOAD_FAIL} from "../../constants/ErrorMessages";
import type {
  RecordIdPayload,
  CreateRecordResponse,
  AudioUploadSignedUrlResponse,
  CreateRecordPayload,
} from "./record.types";

export const createRecord = async (payload: CreateRecordPayload): Promise<CreateRecordResponse> => {
  const response = await AxiosInstance.post<CreateRecordResponse>(CREATE_RECORD_API_URL, payload);
  return response.data;
};

export const createAudioUploadSignedUrl = async (
  payload: RecordIdPayload,
): Promise<AudioUploadSignedUrlResponse> => {
  const response = await AxiosInstance.post<AudioUploadSignedUrlResponse>(
    CREATE_SIGNED_API_URL,
    payload,
  );
  return response.data;
};

export const completeRecord = async (payload: RecordIdPayload): Promise<CreateRecordResponse> => {
  const response = await AxiosInstance.post<CreateRecordResponse>(COMPLETE_RECORD_API_URL, payload);
  return response.data;
};

export const uploadAudio = async (url: string, formData: FormData) => {
  try {
    await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (e) {
    errorToast(AUDIO_UPLOAD_FAIL);
    return false;
  }
};
