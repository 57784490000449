import {ListItemButton, ListItem, ListItemText, ListItemIcon} from "@mui/material";
import {listItem, listItemButton, listItemIcon, listItemText} from "./styles";
import type {SideNavTab} from "../Sidebar/SideNavTabs";
import {useNavigate} from "react-router-dom";
import {useLogoutMutation} from "../../hooks/auth/mutation";
import {useDispatch, useSelector} from "react-redux";
import {logoutSuccess} from "../../redux/slice/authSlice";
import {selectIsRecording, showRecordWarningModal} from "../../redux/slice/recordSlice";
import {queryClient} from "../../api/queryClient";
import {AUTHENTICATED_USER_QUERY_KEY} from "../../hooks/constants";

interface Props {
  tab: SideNavTab;
  active: boolean;
}

const MyListItem = ({tab, active}: Props): JSX.Element => {
  const navigate = useNavigate();
  const logout = useLogoutMutation();
  const dispatch = useDispatch();
  const isRecording = useSelector(selectIsRecording);

  const handleClick = (tab: SideNavTab): void => {
    if (isRecording) {
      dispatch(showRecordWarningModal());
      return;
    }
    if (tab?.key === "logout") {
      logout.mutate(
        {},
        {
          onSuccess: async () => {
            dispatch(logoutSuccess());
            await queryClient.invalidateQueries({queryKey: [AUTHENTICATED_USER_QUERY_KEY]});
            navigate("/login");
          },
        },
      );
    } else if (tab) {
      navigate(tab.path);
    }
  };

  return (
    <ListItem key={tab.key} sx={theme => listItem(theme, active)} onClick={() => handleClick(tab)}>
      <ListItemButton sx={theme => listItemButton(theme, active)}>
        <ListItemIcon sx={theme => listItemIcon(theme, active)}>
          {!active && <tab.icon />}
          {!!active && <tab.activeIcon />}
        </ListItemIcon>
        <ListItemText primary={tab.name} sx={theme => listItemText(theme, active)} />
      </ListItemButton>
    </ListItem>
  );
};

export default MyListItem;
