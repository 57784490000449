import {Theme} from "@mui/material";
import {CSSProperties} from "@mui/material/styles/createMixins";
import theme from "../../../assets/theme";
import hexToRgb from "../../../assets/theme/functions/hexToRgb";

export function appointmentListItemContainer(theme: Theme): CSSProperties {
  return {
    backgroundColor: theme.colors.tertiary.main,
    my: 1,
    py: 1,
    px: 1.5,
    mr: 1,
    borderRadius: theme.borders.borderRadius.md,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    "&:hover": {
      backgroundColor: `rgba(${hexToRgb(theme.colors.grey["200"])},0.8)`,
    },
  };
}

export function appointmentListPatientName(): CSSProperties {
  return {
    fontWeight: "600",
    whiteSpace: "pre",
    fontSize: "14px",
    lineHeight: "1.5",
    color: theme.colors.text.secondary,
  };
}

export function noAppointmentsContainer(theme: Theme): CSSProperties {
  return {
    backgroundColor: theme.colors.tertiary.main,
    p: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    borderRadius: theme.borders.borderRadius.md,
    mt: 1,
  };
}
