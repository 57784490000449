import {type CSSObject, Select, type SelectProps, styled} from "@mui/material";
import theme from "../../../assets/theme";

interface Props extends Omit<SelectProps, "key"> {
  styles?: CSSObject;
}

const StyledSelect = styled(Select)<{styles?: CSSObject}>(({styles}) => ({
  borderRadius: "12px",
  backgroundColor: theme.colors.background.default,
  ".MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  ...styles,
}));

const MySelect = ({styles, ...props}: Props): JSX.Element => {
  return (
    <StyledSelect
      size="small"
      {...props}
      styles={styles}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: "10px",
            marginTop: "10px",
            ...props.MenuProps?.PaperProps?.sx,
          },
        },
      }}>
      {props.children}
    </StyledSelect>
  );
};

export default MySelect;
