import {Box, Typography} from "@mui/material";
import React from "react";
import NoResultsFoundSvg from "../../../../assets/images/icons/NoResultsFound.svg";
import colors from "../../../../assets/theme/base/colors";

function NoResultsFound() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}>
      <Box component={"img"} src={NoResultsFoundSvg} />
      <Typography sx={{color: colors.text.primary, fontWeight: 600, fontSize: "28px"}}>
        No results found {":("}
      </Typography>
      <Typography
        sx={{
          color: colors.text.tertiary,
          fontSize: "14px",
          width: "455px",
          textAlign: "center",
        }}>
        No results available based on your current filter settings. Please adjust your filters and
        try again.
      </Typography>
    </Box>
  );
}

export default NoResultsFound;
