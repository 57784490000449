import styled from "@emotion/styled";
import {TextField, TextFieldProps} from "@mui/material";
import {KeyboardBackspace} from "@mui/icons-material";
import theme from "../../../../assets/theme";
import {DataTableFilters} from "../types";
import {SearchBarStyles} from "./styles";
import {CSSProperties} from "@mui/material/styles/createMixins";
import SearchSvg from "../../../../assets/images/icons/Search.svg";

const StyledTextField = styled(TextField)(() => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "8px !important",
    paddingLeft: `calc(${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    fontSize: "14px",
    width: "18vw",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: theme.borders.borderRadius.lg,
  },
  width: "max-content",
}));

interface props {
  sx?: CSSProperties;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>;
  dataTableFilters: DataTableFilters;
  showSearchBar: boolean;
}

export const SearchBar = ({
  sx,
  handleSearchChange,
  setShowSearchBar,
  showSearchBar,
  dataTableFilters,
  ...props
}: props & TextFieldProps) => (
  <StyledTextField
    {...props}
    value={dataTableFilters.searchText}
    onChange={handleSearchChange}
    InputProps={{
      startAdornment: showSearchBar ? (
        <KeyboardBackspace sx={{cursor: "pointer"}} onClick={() => setShowSearchBar(false)} />
      ) : (
        <img src={SearchSvg} />
      ),
    }}
    sx={{...sx, ...SearchBarStyles(showSearchBar)}}
    fullWidth
  />
);
