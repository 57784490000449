import {Box, SxProps} from "@mui/system";
import {Button, Theme} from "@mui/material";
import {getAbortButtonStyles, getSuccessButtonStyles} from "./styles";
import {CSSProperties} from "@mui/material/styles/createMixins";

interface Props {
  onSuccess: () => void;
  onAbort: () => void;
  successButtonName: string;
  abortButtonName: string;
  buttonSx?: {success?: SxProps<Theme>; abort?: SxProps<Theme>};
  containerSx?: CSSProperties;
}

function SuccessAbortButtons({
  onSuccess,
  onAbort,
  successButtonName,
  abortButtonName,
  buttonSx = {abort: {}, success: {}},
  containerSx,
}: Props) {
  return (
    <Box sx={{display: "flex", justifyContent: "space-between", ...containerSx}}>
      <Button
        onClick={onAbort}
        sx={{...getAbortButtonStyles(), ...buttonSx.abort} as SxProps<Theme>}>
        {abortButtonName}
      </Button>
      <Button
        onClick={onSuccess}
        sx={{...getSuccessButtonStyles(), ...buttonSx.success} as SxProps<Theme>}>
        {successButtonName}
      </Button>
    </Box>
  );
}

export default SuccessAbortButtons;
