import {CSSProperties, Dispatch, SetStateAction, useState} from "react";
import {
  Checkbox,
  MenuItem,
  SelectChangeEvent,
  SelectProps as MuiSelectProps,
  Typography,
  OutlinedInput,
} from "@mui/material";
import FormField from "../FormField";
import MySelect from "../MySelect";
import colors from "../../../assets/theme/base/colors";
import {MultiSelectValues} from "./types";
import {DropdownItem} from "../../../shared/Globals";
import DropdownSvg from "../../../assets/images/icons/Dropdown.svg";

interface Props {
  name: string;
  options: DropdownItem<string>[];
  label: string;
  labelStyles?: CSSProperties;
  containerStyles?: React.CSSProperties;
  SelectProps?: MuiSelectProps;
  selectedFilters: MultiSelectValues;
  setSelectedFilters: Dispatch<SetStateAction<MultiSelectValues>>;
}

function MultiSelect({
  name,
  options,
  label,
  labelStyles,
  containerStyles,
  SelectProps,
  selectedFilters,
  setSelectedFilters,
}: Props) {
  const [selectedLabels, setSelectedLabels] = useState<string[]>(
    (selectedFilters[name] ?? []) as string[],
  );
  const handleChange = (event: SelectChangeEvent<(typeof selectedFilters)[string]>) => {
    const value = event.target.value as string[];
    setSelectedLabels(value);
    const updatedFilters = {
      ...selectedFilters,
      [name]: value,
    };
    setSelectedFilters(updatedFilters);
  };
  return (
    <FormField style={{...containerStyles}} labelStyle={{...labelStyles}} label={label}>
      <MySelect
        {...SelectProps}
        multiple
        sx={{
          background: colors.background.light,
          color: colors.text.primary,
          fontSize: "14px",
          width: "100%",
          ...SelectProps?.style,
          ...SelectProps?.sx,
        }}
        IconComponent={() => <img src={DropdownSvg} style={{height: "20px", width: "20px"}} />}
        value={selectedLabels || []}
        onChange={handleChange as any}
        input={<OutlinedInput label="Select Options" />}
        renderValue={selected =>
          (selected as string[])
            .map((value: string) => options.find(option => option.value === value)?.label)
            .join(", ")
        }>
        {options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            <Checkbox checked={selectedLabels?.indexOf(option.value) > -1} />
            <Typography sx={{fontSize: "14px"}}>{option.label}</Typography>
          </MenuItem>
        ))}
      </MySelect>
    </FormField>
  );
}

export default MultiSelect;
