import {UseQueryOptions, UseQueryResult, useQuery} from "@tanstack/react-query";
import {AuthUser} from "../../api/auth/auth.types";
import {authenticateUser} from "../../api/auth/query";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {authenticateSuccess} from "../../redux/slice/authSlice";
import {AUTHENTICATED_USER_QUERY_KEY} from "../constants";

export const useAuthenticateUserQuery = (
  options: Omit<UseQueryOptions<AuthUser, Error>, "queryKey">,
): UseQueryResult<AuthUser, Error> => {
  return useQuery<AuthUser, Error>({
    queryKey: [AUTHENTICATED_USER_QUERY_KEY],
    queryFn: authenticateUser,
    ...options,
  });
};

export const useAuthenticateUserQueryResult = (
  options: Omit<UseQueryOptions<AuthUser, Error>, "queryKey">,
) => {
  const {data, isLoading, isSuccess} = useAuthenticateUserQuery(options);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(authenticateSuccess(data));
    }
  }, [isSuccess]);

  return {data, isSuccess, isLoading};
};
