import {Box, BoxProps} from "@mui/system";
import colors from "../../../assets/theme/base/colors";
import blur from "../../../assets/theme/base/blur";

interface TrCellProps extends BoxProps {
  children: JSX.Element;
}
export default function TrCell({children, ...props}: TrCellProps) {
  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "start",
        color: colors.text.tertiary + blur["80%"],
        ...props.sx,
      }}>
      {children}
    </Box>
  );
}
