import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomTemplates} from "../../api/user/user.types";
import {getCustomUserTemplates} from "../../api/user/query";

export const useGetCustomUserTemplates = (): UseQueryResult<CustomTemplates, Error> => {
  return useQuery<CustomTemplates, Error>({
    queryKey: ["customUserTemplates"],
    queryFn: () => getCustomUserTemplates(),
  });
};
