import React, {useState} from "react";
import {Box, Typography, Modal, FormControl, SelectChangeEvent} from "@mui/material";
import templateImg from "../../../assets/images/templateSelection.svg";
import theme from "../../../assets/theme";
import {centeredBoxStyles, imageStyles} from "./styles";
import MySelect from "../../common/MySelect";
import MyButton from "../../common/MyButton";
import SoapNotesInProgressModal from "../InProgressModal/SoapNotesInProgressModal";
import {useNavigate} from "react-router-dom";
import {useGenerateSoapNotes} from "../../../hooks/soapNotes/mutation";
import {useGetSoapNotesGenerationStatusQuery} from "../../../hooks/soapNotes/query";
import {SOAP_NOTES_NOT_GENERATED} from "../../../constants/ErrorMessages";
import {SOAP_NOTE_GENERATION_STATUS_POLL_TIME} from "../../../constants/Values";
import {useGetCustomUserTemplates} from "../../../hooks/user/query";
import MyMenuItem from "../../common/MyMenuItem";
import {useUpdateAppointment} from "../../../hooks/appointment/mutation";
import {useCreateRecord} from "../../../hooks/record/mutation";
import {getLocalFormattedDateTime} from "../../../utils/DateUtils";

interface Props {
  isOpen: boolean;
  appointmentId: string;
  isUpcoming?: boolean;
  onTemplateModalClose?: () => void;
}

const ChangeTemplateModal = ({
  isOpen,
  appointmentId,
  isUpcoming = false,
  onTemplateModalClose,
}: Props) => {
  const updateAppointment = useUpdateAppointment();
  const generateSoapNotes = useGenerateSoapNotes();
  const createRecord = useCreateRecord();
  const {data: customUserTemplatesData = []} = useGetCustomUserTemplates();
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = React.useState<number>(-1);
  const [showSoapNotesGenerationProgressModal, setShowSoapNotesGenerationProgressModal] =
    useState<boolean>(false);
  const {refetch, isRefetching} = useGetSoapNotesGenerationStatusQuery(
    appointmentId ? parseInt(appointmentId) : -1,
    {
      enabled: false,
      retry: (_failureCount, error) => error.message === SOAP_NOTES_NOT_GENERATED,
      retryDelay: SOAP_NOTE_GENERATION_STATUS_POLL_TIME,
    },
  );

  const handleRecordCreation = () => {
    createRecord.mutate(
      {appointment_id: parseInt(appointmentId), start_time: getLocalFormattedDateTime()},
      {
        onSuccess: createRecordResponse => {
          navigate(`/recording`, {state: {recordId: createRecordResponse.record_id}});
        },
      },
    );
  };

  const handleSoapNotesGeneration = () => {
    setShowSoapNotesGenerationProgressModal(true);
    generateSoapNotes.mutate(
      {appointment_id: parseInt(appointmentId)},
      {
        onSuccess: async () => {
          await refetch();
          setShowSoapNotesGenerationProgressModal(false);
          onTemplateModalClose?.();
        },
      },
    );
  };

  const handleGenerateSoapNotes = () => {
    if (!appointmentId) return;

    updateAppointment.mutate(
      {
        payload: {
          soap_note_template: selectedTemplate,
        },
        appointmentId: parseInt(appointmentId),
      },
      {
        onSuccess: () => {
          isUpcoming ? handleRecordCreation() : handleSoapNotesGeneration();
        },
      },
    );
  };

  const handleTemplateChange = (event: SelectChangeEvent<number>) => {
    setSelectedTemplate(Number(event.target.value));
  };

  const renderTemplateValue = (value: unknown) => {
    const selectedTemplate = customUserTemplatesData.find(option => option.id === value);
    if (!selectedTemplate || selectedTemplate.id === -1) {
      return (
        <span style={{color: theme.colors.text.primary, fontSize: "14px"}}>Select Template</span>
      );
    }
    return selectedTemplate.template_name;
  };

  return (
    <Modal open={isOpen} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box style={centeredBoxStyles()}>
        <SoapNotesInProgressModal open={showSoapNotesGenerationProgressModal} />
        <Box component="img" src={templateImg} alt="Loading" sx={imageStyles} />
        <Typography id="modal-title" variant="h4" sx={{fontWeight: 600}}>
          Change Template?
        </Typography>
        <Typography
          id="modal-description"
          sx={{mt: 2, textAlign: "center", color: theme.colors.text.secondary, fontSize: "14px"}}>
          Please select a template, if you want to change the template of the generated SOAP notes
        </Typography>
        <FormControl fullWidth variant="outlined" size="small" sx={{my: 4}}>
          <MySelect
            // native={true}
            sx={{backgroundColor: theme.colors.light.secondary, fontSize: "14px"}}
            label="template-select-label"
            value={selectedTemplate}
            renderValue={renderTemplateValue}
            onChange={handleTemplateChange as any}
            data-testid="template-dropdown"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  width: 250,
                },
              },
            }}>
            {customUserTemplatesData?.length > 0
              ? customUserTemplatesData.map(option => (
                  <MyMenuItem key={option.id} value={option.id}>
                    {option.template_name}
                  </MyMenuItem>
                ))
              : null}
          </MySelect>
        </FormControl>
        <MyButton
          fullWidth
          variant="contained"
          text="Generate Notes"
          onClick={handleGenerateSoapNotes}
          isLoading={updateAppointment.isPending || generateSoapNotes.isPending || isRefetching}
          disabled={
            updateAppointment.isPending ||
            generateSoapNotes.isPending ||
            isRefetching ||
            selectedTemplate === -1
          }
        />
      </Box>
    </Modal>
  );
};

export default ChangeTemplateModal;
