import {createTheme} from "@mui/material/styles";
import type {BreakpointsOptions} from "@mui/material/styles";

import colors from "./base/colors";
import breakpoints from "./base/breakpoints";
import typography from "./base/typography";
import boxShadows from "./base/boxShadows";
import borders from "./base/borders";
import globals from "./base/globals";
import container from "./base/container";

import boxShadow from "./functions/boxShadow";
import hexToRgb from "./functions/hexToRgb";
import linearGradient from "./functions/linearGradient";
import pxToRem from "./functions/pxToRem";
import rgba from "./functions/rgba";
import type {MyThemeOptions} from "./interface";
import blur from "./base/blur";

const theme: MyThemeOptions = {
  palette: {
    primary: {
      main: "#20a667", // Customize your primary color here
    },
  },
  breakpoints: {...breakpoints} satisfies BreakpointsOptions,
  colors: {...colors},
  typography: {...typography},
  boxShadows: {...boxShadows},
  borders: {...borders},
  blur: {...blur},
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
        MuiButtonBase: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
};

// Create the base theme
// let baseTheme = createTheme(theme);

// Make the theme responsive
// baseTheme = responsiveFontSizes(baseTheme);

export default createTheme(theme);
