import React from "react";
import {Slide, ToastContainer} from "react-toastify";
import theme from "../../../assets/theme";

const MyToastContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={4000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
      toastStyle={{
        backgroundColor: theme.colors.background.snackbar,
        color: theme.colors.white.main,
      }}
    />
  );
};

export default MyToastContainer;
