import React, {Component, ErrorInfo} from "react";
import Internal404ErrorPage from "./Internal404ErrorPage";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false};
  }

  /* eslint-disable no-console */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error: ", error, errorInfo);
    this.setState({hasError: true});
  }

  render() {
    const {children} = this.props;
    const {hasError} = this.state;

    if (hasError) {
      return <Internal404ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
