export function invertKeyValuePair(obj: Record<string, string | number>) {
  const invertedObject: Record<string, string | number> = {};
  for (const [key, value] of Object.entries(obj)) {
    invertedObject[value] = key;
  }
  return invertedObject;
}
export const copyTextToClipboard = async (text: string) => {
  return await navigator.clipboard.writeText(text);
};
