export const FIELD_IS_REQUIRED = "This field is required.";
export const INACTIVE_ACCOUNT = "Your account is inactive.";
export const INVALID_CREDENTIALS = "Entered email/password is incorrect. Please try again.";
export const INVALID_EMAIL_FORMAT = "Enter a valid email.";
export const TNS_NOT_ACCEPTED =
  'You need to accept "Terms of Service" and "Privacy Policy" for signing up.';
export const EMAIL_ALREADY_REGISTERED = "This email is already registered with us.";
export const SOMETHING_WENT_WRONG = "Something went wrong. Please try again.";
export const INVALID_OTP = "Entered OTP is not valid. Please try different one.";
export const INVALID_PASSWORD =
  "The password should contain atleast 8 characters which should include a number, an lower case, an upper case and a special character.";
export const PASSWORD_NOT_MATCHING = "Passwords do not match.";
export const EMAIL_NOT_REGISTERED = "User with this email does not exists.";
export const INVALID_PASSWORD_LINK = "This is invalid reset password link.";
export const EMAIL_NOT_VERIFIED = "Email is not verified.";
export const TRANSCRIPTION_NOT_COMPLETE = "Transcription not completed yet.";
export const INVALID_RECORD_ID = "Something went wrong. Could not start recording.";
export const SPEECH_RECOGNITION_ERR =
  "An error occured with live transcription. Please carry on with audio recording.";
export const AUDIO_UPLOAD_FAIL = "Something went wrong. Audio could not be uploaded.";
export const MULTIPLE_AUDIO_FILE_ERR = "Please upload only one file.";
export const FILE_TYPE_ERR = "Please upload a valid audio file.";
export const SOAP_NOTES_NOT_GENERATED = "SOAP Notes not generated yet. Please wait for a while.";
