import React, {CSSProperties} from "react";

interface Props {
  color: string;
  styles?: CSSProperties;
  dataTestId?: string;
}

function MicIcon({styles, color, dataTestId}: Props) {
  return (
    <svg
      style={{...styles}}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      data-testid={dataTestId}>
      <g clip-path="url(#clip0_1539_67900)">
        <path
          d="M12.5816 8.31381C12.5816 8.02171 12.3582 7.79834 12.0661 7.79834C11.774 7.79834 11.5506 8.02171 11.5506 8.31381C11.5506 10.3241 9.91831 11.9565 7.90798 11.9565C5.89764 11.9565 4.26532 10.3241 4.26532 8.31381C4.26532 8.02171 4.04195 7.79834 3.74985 7.79834C3.45775 7.79834 3.23438 8.02171 3.23438 8.31381C3.23438 10.7022 5.02134 12.7125 7.3925 12.9702V14.3448H5.51963C5.22753 14.3448 5.00416 14.5682 5.00416 14.8603C5.00416 15.1524 5.22753 15.3758 5.51963 15.3758H10.2963C10.5884 15.3758 10.8118 15.1524 10.8118 14.8603C10.8118 14.5682 10.5884 14.3448 10.2963 14.3448H8.42345V12.9702C10.7946 12.7125 12.5816 10.7022 12.5816 8.31381Z"
          fill={color}
        />
        <path
          d="M7.90852 0.793457C6.32774 0.793457 5.03906 2.08213 5.03906 3.66291V8.31933C5.03906 9.91729 6.32774 11.1888 7.90852 11.206C9.48929 11.206 10.778 9.91729 10.778 8.33651V3.66291C10.778 2.08213 9.48929 0.793457 7.90852 0.793457Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1539_67900">
          <rect
            width="14.605"
            height="14.605"
            fill="white"
            transform="translate(0.609375 0.785645)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MicIcon;
