import type {GlobalStyles} from "../interface";
import colors from "./colors";

const {info, dark} = colors;

const globals: GlobalStyles = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  hr: {
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
};

export type DateFormats = "mm/dd/yyyy" | "dd/mm/yyyy" | "yyyy/mm/dd";

export default globals;
