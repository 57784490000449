import AxiosInstance from "../../axios/axiosInstance";
import {APPOINTMENT_LIST_API_URL, getAppointmentDetailsApiUrl} from "../../constants/ApiUrls";
import {ListResponse} from "../../shared/interfaces";
import {createDynamicUrl} from "../../utils/UrlUtils";
import {Appointment, AppointmentIdPaylod, GetAppointmentListParams} from "./appointment.interface";

export const getAppointmentList = async (
  params: GetAppointmentListParams,
): Promise<ListResponse<Appointment>> => {
  const response = await AxiosInstance.get<ListResponse<Appointment>>(
    createDynamicUrl(APPOINTMENT_LIST_API_URL, params as Record<string, string | string[]>),
  );
  return response.data;
};

export const getAppointmentDetails = async (params: AppointmentIdPaylod): Promise<Appointment> => {
  const response = await AxiosInstance.get<Appointment>(
    getAppointmentDetailsApiUrl(params.appointment_id),
    {
      params,
    },
  );
  return response.data;
};
