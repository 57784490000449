import "./App.css";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Router from "./routes/Router";
import theme from "./assets/theme";

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}

export default App;
