import {styled} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import colors from "../../../assets/theme/base/colors";
import borders from "../../../assets/theme/base/borders";
import theme from "../../../assets/theme";

export const MyDataGrid = styled(DataGrid)({
  ".MuiDataGrid-root": {
    whiteSpace: "normal !important",
    wordWrap: "break-word",
  },
  ".MuiDataGrid-cell": {
    "& .MuiTypography-root": {
      whiteSpace: "normal !important",
      wordWrap: "break-word",
    },
  },
  borderWidth: "0px",
  height: "75vh",
  "& .MuiDataGrid-row": {
    minHeight: "66px !important",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: colors.text.tertiary,
  },
});

export const getContainerStyles = () => ({
  width: "100%",
  background: "white",
  px: 4,
  py: 3,
  my: 5,
  borderRadius: borders.borderRadius.lg,
  [theme.breakpoints.down("sm")]: {
    width: "90vw",
  },
});

export const getModalSxStyles = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const getFilterModalStyles = () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: "34px 24px",
});
