import {Typography, MenuItem} from "@mui/material";
import {Box} from "@mui/system";
import {CSSProperties, Dispatch, SetStateAction} from "react";
import MySelect from "../../../MySelect";
import {
  CountAndPageSizeContainer,
  CountContainer,
  CountTypography,
  CountValue,
  PageSizeSelectContainer,
  PageSizeSelectSx,
  PageSizeTypography,
} from "../styles";
import {DataTableFilters} from "../../types";
import {PAGE_SIZE_OPTIONS} from "../../constants";

interface CountAndPageSizeProps<DataType> {
  count: number;
  data: DataType[];
  display: "flex" | "none";
  styles?: CSSProperties;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  setDataTableFilters: Dispatch<SetStateAction<DataTableFilters>>;
}
export default function CountAndPageSize<DataType>({
  count,
  display,
  styles,
  pageSize,
  setPageSize,
  setDataTableFilters,
}: CountAndPageSizeProps<DataType>) {
  // Generate the options for the page size dropdown based on data length.
  const generatePageSizeOptions = () => {
    return PAGE_SIZE_OPTIONS.map(ele => {
      return (
        <MenuItem key={ele} sx={{fontSize: "14px"}} value={ele}>
          {ele}
        </MenuItem>
      );
    });
  };
  return (
    <Box sx={CountAndPageSizeContainer(styles, display)}>
      <Box sx={CountContainer()}>
        <Typography sx={CountTypography()}>Count: </Typography>
        <Typography sx={CountValue()} fontWeight={600}>
          {count}
        </Typography>
      </Box>
      <Box sx={PageSizeSelectContainer()}>
        <Typography sx={PageSizeTypography()}>Page size: </Typography>
        <MySelect
          sx={PageSizeSelectSx()}
          value={pageSize}
          onChange={e => {
            setPageSize(e.target.value as number);
            setDataTableFilters(prev => ({...prev, limit: pageSize}));
          }}>
          {generatePageSizeOptions()}
        </MySelect>
      </Box>
    </Box>
  );
}
