import {Box, Typography} from "@mui/material";
import React from "react";

const Footer = (): JSX.Element => {
  return (
    <Box
      style={{
        bottom: 0,
        marginTop: "1rem",
        textAlign: "center",
      }}
      width="100%"
      display="flex"
      flexDirection={{xs: "column", lg: "row"}}
      justifyContent="center"
      alignItems="center"
      px={1.5}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        px={1.5}>
        <Typography
          variant="button"
          fontWeight="bold"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}>
          {/* {!!brand && <Box component="img" src={brand} alt="Argon Logo" width="8rem" mr={1} />} */}
        </Typography>
        &copy; {new Date().getFullYear()} | All Rights Reserved |
        {/* <ArgonBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <Icon color="inherit" fontSize="inherit">
            favorite
          </Icon>
        </ArgonBox>
        by */}
        {/* <Link href={href} target="_blank">
          <Typography variant="button" fontWeight="medium">
            &nbsp;{name}&nbsp;
          </Typography>
        </Link> */}
      </Box>
    </Box>
  );
};

export default Footer;
