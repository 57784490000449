import {Box, Button, Typography} from "@mui/material";
import {styled} from "@mui/system";
import type {ButtonProps} from "@mui/material";
import theme from "../../../assets/theme";
import ButtonLoader from "./ButtonLoader";

type ButtonVariant = "text" | "outlined" | "contained";
type SizeVariant = "small" | "medium" | "large";

interface Props extends ButtonProps {
  text: string;
  variant?: ButtonVariant;
  size?: SizeVariant;
  isLoading?: boolean;
  icon?: string;
}

const StyledButton = styled(Button)<{variant: ButtonVariant}>(({variant}) => ({
  borderRadius: "10px",
  textTransform: "none",
  padding: "10px",
  color: variant === "outlined" ? theme.palette.primary.main : theme.colors.white.main,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: variant === "outlined" ? "transparent" : theme.colors.primary.focus,
    boxShadow: "0px 4px 8px 0px rgba(33, 166, 103, 0.40)",
  },
  "&.Mui-disabled": {
    backgroundColor: theme.colors.secondary.main,
  },
}));

const MyButton = ({
  text = "Click",
  variant = "contained",
  size = "large",
  isLoading,
  icon,
  ...props
}: Props): JSX.Element => {
  return (
    <StyledButton variant={variant} size={size} {...props}>
      {!!icon && <Box component={"img"} src={icon} sx={{mr: 2}} />}
      <Typography sx={{fontSize: props.style?.fontSize || "inherit"}}>{text}</Typography>
      {!!isLoading && <ButtonLoader />}
    </StyledButton>
  );
};

export default MyButton;
