import {Box} from "@mui/material";
import {BoxProps} from "@mui/system";
import {getModalContainerStyles, getModalContentStyles} from "./styles";

interface Props extends BoxProps {
  Component: JSX.Element;
  closeModal: () => void;
  containerProps?: BoxProps;
}

function MyModal({Component, containerProps, closeModal, ...props}: Props) {
  return (
    <Box
      className="container"
      onClick={e => {
        if ((e.target as HTMLDivElement).classList.contains("container")) closeModal();
      }}
      {...containerProps}
      sx={{
        ...getModalContainerStyles(),
        ...containerProps?.sx,
      }}>
      <Box
        {...props}
        sx={{
          ...getModalContentStyles(),
          ...props.sx,
        }}>
        {Component}
      </Box>
    </Box>
  );
}

export default MyModal;
