import {styled} from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import type {MenuItemProps} from "@mui/material";
import type {CSSProperties} from "@mui/material/styles/createMixins";
import React from "react";

interface Props extends MenuItemProps {
  style?: CSSProperties;
}

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  fontSize: "14px !important",
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.selected,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MyMenuItem = ({style, ...props}: Props): JSX.Element => {
  return <StyledMenuItem {...props} sx={{...style}} />;
};

export default MyMenuItem;
