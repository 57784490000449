import {Box, Tooltip, Typography} from "@mui/material";
import React from "react";
import {Appointment} from "../../../api/appointment/appointment.interface";
import {appointmentListItemContainer, appointmentListPatientName} from "./styles";
import {truncateSentence} from "../../../utils/StringConversions";
import {extractTimeFromTimestamp} from "../../../utils/DateUtils";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../../redux/slice/authSlice";
import {ADHOC, APPOINTMENT_TYPES} from "../../../constants/Values";
import RecordingAnimation from "./../../../assets/animations/Recording.gif";
import {
  selectCurrentAppointmentId,
  selectIsRecording,
  showRecordWarningModal,
} from "../../../redux/slice/recordSlice";
import {useNavigate} from "react-router-dom";

interface Props {
  appointment: Appointment;
}

const AppointmentListItem = ({appointment}: Props) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const isRecording = useSelector(selectIsRecording);
  const navigate = useNavigate();
  const appointmentId = useSelector(selectCurrentAppointmentId);

  const handleClick = (): void => {
    if (isRecording) {
      dispatch(showRecordWarningModal());
      return;
    }
    navigate(`/appointment/${appointment.id}`);
  };

  const patientName = appointment.patient
    ? appointment.patient.first_name + " " + appointment.patient.last_name
    : appointment.appointment_name;

  const appointmentTimeAndVisitType = `${extractTimeFromTimestamp(appointment.appointment_time)} . ${appointment.appointment_type === APPOINTMENT_TYPES.ADHOC ? ADHOC : user?.emr_system_details?.emr_name.toUpperCase() + " Visit"}`;

  return (
    <Box sx={theme => appointmentListItemContainer(theme)} onClick={handleClick}>
      <Box sx={{textOverflow: "ellipsis", whiteSpace: "normal", wordBreak: "break-word"}}>
        <Tooltip title={patientName}>
          <Typography style={appointmentListPatientName()}>
            {`${truncateSentence(patientName, 20)}`}
          </Typography>
        </Tooltip>
        <Typography variant="subtitle1" fontSize={"14px"} lineHeight="1.5">
          {appointmentTimeAndVisitType}
        </Typography>
      </Box>
      {!!isRecording && appointmentId === appointment.id && (
        <Box
          component={"img"}
          src={RecordingAnimation}
          sx={{height: "30px", width: "35px", ml: 0.5}}
        />
      )}
    </Box>
  );
};

export default AppointmentListItem;
