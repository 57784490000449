import {DateFormats} from "../assets/theme/base/globals";

export function extractTimeFromTimestamp(timestamp: string): string {
  if (!timestamp) return "";
  // Use a regular expression to match the time part of the timestamp
  const timeMatch = timestamp.match(/T(\d{2}):(\d{2}):(\d{2})/);

  // If the regular expression matches, extract the hour, minute, and second parts
  if (timeMatch && timeMatch[1] && timeMatch[2]) {
    let hours = parseInt(timeMatch[1]);
    const minutes = timeMatch[2];
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  }

  // If the timestamp is invalid or doesn't match the expected format, throw an error
  return "";
}

export const extractDateFromTimestamp = (
  timestamp: string,
  format = "mm/dd/yyyy" as DateFormats,
  separator = "/" as "-" | "/",
) => {
  if (!timestamp) return "";
  const [year, month, day] = timestamp.split("T")[0].split("-");

  return format
    .replace("yyyy", year)
    .replace("mm", month)
    .replace("dd", day)
    .replace(/\//g, separator);
};

export const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

export function getWeekStartAndEnd(dateString: string): {firstDate: string; lastDate: string} {
  const inputDate = new Date(dateString);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = inputDate.getDay();

  // Calculate the difference between the current day and Monday (1) and Sunday (0)
  const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
  const diffToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

  // Calculate the Monday and Sunday of that week
  const mondayDate = new Date(inputDate);
  mondayDate.setDate(inputDate.getDate() + diffToMonday);

  const sundayDate = new Date(inputDate);
  sundayDate.setDate(inputDate.getDate() + diffToSunday);

  // Format the dates as YYYY-MM-DD
  const formatDate = (date: Date) => date.toISOString().split("T")[0];

  return {
    firstDate: formatDate(mondayDate),
    lastDate: formatDate(sundayDate),
  };
}

export function getMonthStartAndEnd(dateString: string): {firstDate: string; lastDate: string} {
  const inputDate = new Date(dateString);

  // Calculate the first and last days of the month using UTC methods
  const firstDayOfMonth = new Date(
    Date.UTC(inputDate.getUTCFullYear(), inputDate.getUTCMonth(), 1),
  );
  const lastDayOfMonth = new Date(
    Date.UTC(inputDate.getUTCFullYear(), inputDate.getUTCMonth() + 1, 0),
  );

  // Format the dates as YYYY-MM-DD using UTC methods
  const formatDate = (date: Date) => date.toISOString().split("T")[0];

  return {
    firstDate: formatDate(firstDayOfMonth),
    lastDate: formatDate(lastDayOfMonth),
  };
}

/**
 * Formats a given number of seconds into a MM:SS string.
 * @param seconds - The number of seconds to format.
 * @returns A string representing the formatted time.
 */
export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

export const getLocalFormattedDateTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};
