import {useQuery, UseQueryOptions, UseQueryResult} from "@tanstack/react-query";
import {getSoapNotes, getSoapNotesGenerationStatus} from "../../api/soapNotes/query";
import {
  GetSoapNoteResponse,
  SoapNoteGenerationStatusResponse,
} from "../../api/soapNotes/soapNotes.types";

export const useGetSoapNotesQuery = (
  appointment_id: number,
  options: Omit<UseQueryOptions<GetSoapNoteResponse, Error>, "queryKey">,
): UseQueryResult<GetSoapNoteResponse, Error> => {
  return useQuery<GetSoapNoteResponse, Error>({
    queryKey: ["soapNotes", appointment_id],
    queryFn: () => getSoapNotes(appointment_id),
    ...options,
  });
};

export const useGetSoapNotesGenerationStatusQuery = (
  appointment_id: number,
  options: Omit<UseQueryOptions<SoapNoteGenerationStatusResponse, Error>, "queryKey">,
): UseQueryResult<SoapNoteGenerationStatusResponse, Error> => {
  return useQuery<SoapNoteGenerationStatusResponse, Error>({
    queryKey: ["soapNotesGenerationStatus", appointment_id],
    queryFn: () => getSoapNotesGenerationStatus(appointment_id),
    ...options,
  });
};
