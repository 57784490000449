import {type UseMutationResult, useMutation} from "@tanstack/react-query";
import type {
  LoginPayload,
  SendEmailOtpResponse,
  VerifyEmailOtpPayload,
  SignUpUserPayload,
  AuthUser,
  ForgotPasswordResponse,
  ConfirmForgotPasswordPayload,
} from "../../api/auth/auth.types";
import {
  confirmForgotPassword,
  forgotPassword,
  login,
  logout,
  sendEmailOtp,
  signUpUser,
  verifyEmailOtp,
} from "../../api/auth/mutation";
import type {Error} from "../../shared/interfaces";

export const useLoginMutation = (): UseMutationResult<AuthUser, Error, LoginPayload> => {
  return useMutation<AuthUser, Error, LoginPayload>({mutationFn: login});
};

export const useLogoutMutation = (): UseMutationResult<void, Error, unknown> => {
  return useMutation<void, Error, unknown>({mutationFn: logout});
};

export const useSendEmailOtpMutation = (): UseMutationResult<
  SendEmailOtpResponse,
  Error,
  string
> => {
  return useMutation<SendEmailOtpResponse, Error, string>({mutationFn: sendEmailOtp});
};

export const useVerifyEmailOtpMutation = (): UseMutationResult<
  object,
  Error,
  VerifyEmailOtpPayload
> => {
  return useMutation<object, Error, VerifyEmailOtpPayload>({mutationFn: verifyEmailOtp});
};

export const useSignUpUserMutation = (): UseMutationResult<AuthUser, Error, SignUpUserPayload> => {
  return useMutation<AuthUser, Error, SignUpUserPayload>({mutationFn: signUpUser});
};

export const useForgotPasswordMutation = (): UseMutationResult<
  ForgotPasswordResponse,
  Error,
  string
> => {
  return useMutation<ForgotPasswordResponse, Error, string>({mutationFn: forgotPassword});
};

export const useConfirmForgotPasswordMutation = (): UseMutationResult<
  ForgotPasswordResponse,
  Error,
  ConfirmForgotPasswordPayload
> => {
  return useMutation<ForgotPasswordResponse, Error, ConfirmForgotPasswordPayload>({
    mutationFn: confirmForgotPassword,
  });
};
