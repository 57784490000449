import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import type {ReduxState} from "../interface";

const initialState: string = "";

//Slice
const currentEditingSoapNoteSlice = createSlice({
  name: "currentEditingSoapNote",
  initialState,
  reducers: {
    updateCurrentEditingSoapNote: (state, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export default currentEditingSoapNoteSlice.reducer;

//Actions
export const {updateCurrentEditingSoapNote} = currentEditingSoapNoteSlice.actions;

//Selectors
export const selectCurrentEditingSoapNote = (state: ReduxState): string =>
  state.currentEditingSoapNote;
