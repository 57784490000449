import {Box, Typography} from "@mui/material";
import React from "react";
import theme from "../../../assets/theme";
import type {CSSProperties} from "@mui/material/styles/createMixins";

interface Props {
  children: React.ReactNode;
  style?: CSSProperties;
  label: string;
  error?: boolean;
  errorMsg?: string;
  labelStyle?: CSSProperties;
  ariaErrorLabel?: string;
}

const FormField = ({
  label,
  error,
  errorMsg,
  children,
  style,
  labelStyle,
  ariaErrorLabel,
}: Props): JSX.Element => {
  return (
    <Box style={{...style}}>
      <Typography variant="h6" sx={{color: theme.colors.text.secondary, ...labelStyle}}>
        {label}
      </Typography>
      <Box>{children}</Box>
      <Typography
        aria-label={ariaErrorLabel}
        variant="h6"
        sx={{color: theme.colors.error.main, fontSize: "16px"}}>
        {!!error && errorMsg}
      </Typography>
    </Box>
  );
};

export default FormField;
