import React from "react";
import {Box} from "@mui/material";
import {styled, keyframes} from "@mui/system";
import theme from "./../../../assets/theme";

const bouncingLoader = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-3px);
  }
`;

const BouncingLoader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginLeft: "8px",
});

const LoaderDot = styled(Box)({
  width: "8px",
  height: "8px",
  margin: "3px",
  borderRadius: "50%",
  backgroundColor: theme.colors.white.main,
  opacity: 1,
  animation: `${bouncingLoader} 0.6s infinite alternate`,
  "&:nth-of-type(2)": {
    animationDelay: "0.1s",
  },
  "&:nth-of-type(3)": {
    animationDelay: "0.3s",
  },
});

const ButtonLoader = () => (
  <BouncingLoader>
    <LoaderDot />
    <LoaderDot />
    <LoaderDot />
  </BouncingLoader>
);

export default ButtonLoader;
