import {CSSProperties} from "@mui/material/styles/createMixins";
import theme from "../../../assets/theme";

export function centeredBoxStyles(): CSSProperties {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    width: "363px",
    backgroundColor: theme.colors.background.light,
    padding: "3%",
    borderRadius: "20px",
  };
}

export function imageStyles(): CSSProperties {
  return {
    width: "25%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    mb: "3%",
  };
}
