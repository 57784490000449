import {type Middleware, combineReducers, configureStore} from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import currentEditingSoapNoteReducer from "./slice/currentEditingSoapNoteSlice";
import {errorHandlerMiddleware} from "./middlewares/errorMiddleware";
import recordSlice from "./slice/recordSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  currentEditingSoapNote: currentEditingSoapNoteReducer,
  currentRecording: recordSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorHandlerMiddleware as Middleware),
});

export default store;
