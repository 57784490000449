import {SxProps, Theme} from "@mui/system";
import borders from "../../../../assets/theme/base/borders";
import colors from "../../../../assets/theme/base/colors";
import theme from "../../../../assets/theme";
import blur from "../../../../assets/theme/base/blur";

export function getTitleTypographyStyles(titleStyles?: React.CSSProperties): SxProps<Theme> {
  return {
    ...titleStyles,
  };
}

export function getCloseIconStyles(): SxProps<Theme> {
  return {
    fontSize: "16px",
    color: colors.text.tertiary,
  };
}

export function getCloseIconContainerStyles(): SxProps<Theme> {
  return {
    background: colors.text.tertiary + blur["20%"],
    borderWidth: "0px",
    p: "3px",
    filter: "initial",
  };
}

export function getDescriptionTypographyStyles(
  descriptionStyles?: React.CSSProperties,
): SxProps<Theme> {
  return {
    fontSize: "12px",
    color: colors.text.tertiary + blur["80%"],
    ...descriptionStyles,
  };
}

export function getMultiSelectStyles(): SxProps<Theme> {
  return {
    width: "380px",
    mt: "0px",
    p: "10px 16px",
    "& .MuiSelect-select": {p: 0},
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  };
}

export function getLabelStyles(): React.CSSProperties {
  return {
    color: colors.text.tertiary + blur["80%"],
    fontSize: "12px",
    marginBottom: "4px",
  };
}

export function getContainerStyles(): React.CSSProperties {
  return {
    marginBottom: "16px",
  };
}

export function getButtonSx() {
  return {
    success: {borderRadius: borders.borderRadius.lg, mt: 2, width: "50%"},
    abort: {mt: 2, width: "50%", "&: hover": {border: "none"}},
  };
}
