import type {UseMutationResult} from "@tanstack/react-query";
import {useMutation} from "@tanstack/react-query";
import type {
  CreateAppointmentPayload,
  CreateAppointmentResponse,
  UpdateAppointmentParams,
  UpdateAppointmentResponse,
} from "../../api/appointment/appointment.types";
import {createAppointment, updateAppointment} from "../../api/appointment/mutation";
import type {Error} from "../../shared/interfaces";
import {queryClient} from "../../api/queryClient";
import {APPOINTMENT_LIST_QUERY_KEY} from "../constants";

export const useCreateAppointment = (): UseMutationResult<
  CreateAppointmentResponse,
  Error,
  CreateAppointmentPayload
> => {
  return useMutation<CreateAppointmentResponse, Error, CreateAppointmentPayload>({
    mutationFn: createAppointment,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [APPOINTMENT_LIST_QUERY_KEY]});
    },
  });
};

export const useUpdateAppointment = (): UseMutationResult<
  UpdateAppointmentResponse,
  Error,
  UpdateAppointmentParams
> => {
  return useMutation<UpdateAppointmentResponse, Error, UpdateAppointmentParams>({
    mutationFn: ({payload, appointmentId}) => updateAppointment(payload, appointmentId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [APPOINTMENT_LIST_QUERY_KEY]});
    },
  });
};
