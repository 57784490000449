import {Box, Typography, MenuItem, SelectChangeEvent} from "@mui/material";
import {Dispatch, SetStateAction, useCallback, useState} from "react";
import theme from "../../../../assets/theme";
import {SearchBar} from "../SearchBar";
import MyIcon from "../../MyIcon";
import MySelect from "../../MySelect";
import DropdownSvg from "../../../../assets/images/icons/Dropdown.svg";
import FilterSvg from "../../../../assets/images/icons/Filter.svg";
import CountAndPageSize from "./CountAndPageSize";
import {DataTableFilters, DataTableInitialState, SelectedPeriod} from "../types";

import {
  CountAndPageSizeContainerProp,
  DropdownIconStyles,
  getCountAndPageSizeStyles,
  getFilterIconContainerStyles,
  getHeaderContainerStyles,
  getIconStyles,
  getPeriodSelectStyles,
  getSearchIconContainerStyles,
  getTypographyStyles,
  SearchBarStylesProp,
} from "./styles";
import SearchSvg from "../../../../assets/images/icons/Search.svg";

export default function TableHeader<DataType>({
  count,
  data,
  setShowCalender,
  setShowFilter,
  pageSize,
  setPageSize,
  dataTableFilters,
  setDataTableFilters,
  initialState,
}: {
  count: number;
  data: DataType[];
  setShowCalender: Dispatch<SetStateAction<boolean>>;
  setShowFilter: Dispatch<SetStateAction<boolean>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  dataTableFilters: DataTableFilters;
  setDataTableFilters: Dispatch<SetStateAction<DataTableFilters>>;
  initialState: DataTableInitialState;
}) {
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDataTableFilters(prev => ({...prev, searchText: e.target.value}));
    },
    [dataTableFilters.searchText],
  );

  const handleSelectedPeriodChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    if (value === SelectedPeriod.CUSTOM) {
      setShowCalender(true);
    } else {
      setDataTableFilters(prev => ({
        ...prev,
        selectedPeriod: e.target.value as SelectedPeriod,
      }));
    }
  };

  const isFiltersApplied = Object.values(dataTableFilters.selectedFilters).some(
    value => !Array.isArray(value) || value.length > 0,
  );

  return (
    <>
      <Box sx={getHeaderContainerStyles()}>
        <Box sx={{flex: 1, display: "flex", alignItems: "center"}}>
          <Typography sx={getTypographyStyles()}>Appointments</Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center", flex: 1}}>
          <CountAndPageSize<DataType>
            setDataTableFilters={setDataTableFilters}
            count={count}
            data={data}
            display={theme.breakpoints.down("lg") ? "none" : "flex"}
            pageSize={pageSize}
            setPageSize={setPageSize}
            styles={getCountAndPageSizeStyles()}
          />
          <Box sx={{display: "flex", alignItems: "center"}}>
            {!!initialState?.showSearchBar && (
              <>
                <SearchBar
                  placeholder={"Search by MRN or patient name"}
                  sx={{[theme.breakpoints.down("md")]: {display: "none"}}}
                  dataTableFilters={dataTableFilters}
                  handleSearchChange={handleSearchChange}
                  setShowSearchBar={setShowSearchBar}
                  showSearchBar={showSearchBar}
                />
                <MyIcon
                  Icon={() => <img style={{height: "14px", width: "14px"}} src={SearchSvg} />}
                  sx={getIconStyles()}
                  containerProps={{sx: getSearchIconContainerStyles(showSearchBar)}}
                />
              </>
            )}
            {!!initialState?.showFilterIcon && (
              <MyIcon
                Icon={() => (
                  <img
                    src={FilterSvg}
                    style={getIconStyles()}
                    aria-label="Open Filter"
                    onClick={() => setShowFilter(true)}
                  />
                )}
                containerProps={{sx: getFilterIconContainerStyles(isFiltersApplied)}}
              />
            )}
            {!!initialState?.showPeriodSelect && (
              <MySelect
                value={dataTableFilters.selectedPeriod}
                sx={getPeriodSelectStyles()}
                IconComponent={() => <img src={DropdownSvg} style={DropdownIconStyles()} />}
                onChange={handleSelectedPeriodChange}
                onClick={e => {
                  if ((e.target as HTMLInputElement).innerText === SelectedPeriod.CUSTOM) {
                    setShowCalender(true);
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      [theme.breakpoints.down("sm")]: {
                        width: "90px",
                        "& li": {fontSize: "12px"},
                      },
                    },
                  },
                }}
                fullWidth>
                {Object.keys(SelectedPeriod).map(key => (
                  <MenuItem
                    sx={{fontSize: "14px"}}
                    key={key}
                    value={SelectedPeriod[key as keyof typeof SelectedPeriod]}>
                    {SelectedPeriod[key as keyof typeof SelectedPeriod]}
                  </MenuItem>
                ))}
              </MySelect>
            )}
          </Box>
        </Box>
      </Box>
      <SearchBar
        placeholder={"Search by MRN or patient name"}
        sx={SearchBarStylesProp(showSearchBar)}
        dataTableFilters={dataTableFilters}
        handleSearchChange={handleSearchChange}
        setShowSearchBar={setShowSearchBar}
        showSearchBar={showSearchBar}
      />
      <CountAndPageSize<DataType>
        count={count}
        data={data}
        display={theme.breakpoints.down("lg") ? "flex" : "none"}
        styles={CountAndPageSizeContainerProp()}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setDataTableFilters={setDataTableFilters}
      />
    </>
  );
}
