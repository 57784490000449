import AxiosInstance from "../../axios/axiosInstance";
import {CREATE_APPOINTMENT_API_URL, updateAppointmentApiUrl} from "../../constants/ApiUrls";
import type {
  CreateAppointmentPayload,
  CreateAppointmentResponse,
  UpdateAppointmentPayload,
  UpdateAppointmentResponse,
} from "./appointment.types";

export const createAppointment = async (
  payload: CreateAppointmentPayload,
): Promise<CreateAppointmentResponse> => {
  const response = await AxiosInstance.post<CreateAppointmentResponse>(
    CREATE_APPOINTMENT_API_URL,
    payload,
  );
  return response.data;
};

export const updateAppointment = async (
  payload: UpdateAppointmentPayload,
  appointmentId: number,
): Promise<UpdateAppointmentResponse> => {
  const response = await AxiosInstance.put<UpdateAppointmentResponse>(
    updateAppointmentApiUrl(appointmentId),
    payload,
  );
  return response.data;
};
