import type {UseMutationResult} from "@tanstack/react-query";
import {useMutation} from "@tanstack/react-query";
import {SoapNoteGenerationStatusResponse} from "../../api/soapNotes/soapNotes.types";
import {generateSoapNotes} from "../../api/soapNotes/mutation";

export const useGenerateSoapNotes = (): UseMutationResult<
  SoapNoteGenerationStatusResponse,
  Error,
  {appointment_id: number}
> => {
  return useMutation({
    mutationFn: ({appointment_id}: {appointment_id: number}) => generateSoapNotes(appointment_id),
  });
};
