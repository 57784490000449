import AxiosInstance from "../../axios/axiosInstance";
import {getSoapNotesApiUrl, getSoapNotesGenerationStatusApiUrl} from "../../constants/ApiUrls";
import {SOAP_NOTES_NOT_GENERATED} from "../../constants/ErrorMessages";
import {
  GetSoapNoteResponse,
  SOAP_NOTE_GENERATION_STATUS,
  SoapNoteGenerationStatusResponse,
} from "./soapNotes.types";

export const getSoapNotes = async (appointment_id: number): Promise<GetSoapNoteResponse> => {
  const response = await AxiosInstance.get<GetSoapNoteResponse>(getSoapNotesApiUrl(appointment_id));
  return response.data;
};

export const getSoapNotesGenerationStatus = async (
  appointment_id: number,
): Promise<SoapNoteGenerationStatusResponse> => {
  const response = await AxiosInstance.get<SoapNoteGenerationStatusResponse>(
    getSoapNotesGenerationStatusApiUrl(appointment_id),
  );

  const data = response.data;
  if (
    data.soap_note_generation_status === SOAP_NOTE_GENERATION_STATUS.INPROGRESS ||
    data.soap_note_generation_status === SOAP_NOTE_GENERATION_STATUS.PENDING
  ) {
    throw new Error(SOAP_NOTES_NOT_GENERATED);
  }
  return data;
};
